/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  tokens: [],
  stos: [],
  loading: false
};

export default function tokens(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case Types.TOKENS_REQUEST:
      return produce(state, draft => {
        draft.loading = true
      });
    case Types.TOKENS_SUCCESS:

      return produce(state, draft => {
        draft.loading = false
        draft.tokens = payload
      });

    case Types.TOKENS_FAILURE:
      return produce(state, draft => {
        draft.loading = false
      });

    // STOs

    case Types.STO_REQUEST:
      return produce(state, draft => {
        draft.loading = true
      });
    case Types.STO_SUCCESS:
      return produce(state, draft => {
        draft.loading = false
        draft.stos = payload
      });

    case Types.STO_FAILURE:
      return produce(state, draft => {
        draft.loading = false
      });

    // STOs - Buy

    case Types.BUY_STO_REQUEST:
      return produce(state, draft => {
        draft.loading = true
      });
    case Types.BUY_STO_SUCCESS:
      return produce(state, draft => {
        draft.loading = false
      });

    case Types.BUY_STO_FAILURE:
      return produce(state, draft => {
        draft.loading = false
      });

    default:
      return state;
  }
};
