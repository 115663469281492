/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React from 'react';
import { Menu } from 'antd';
import { BookOutlined, WalletOutlined, ShopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


export default function Navigation({ current }) {
  return (
    <Menu selectedKeys={[current]} mode="horizontal">
      <Menu.Item key="balance" icon={<WalletOutlined />}>
        <Link to="/">Carteira</Link>
      </Menu.Item>
      <Menu.Item key="sto" icon={<ShopOutlined />}>
        <Link to="/sto">Lista de Ativos</Link>
      </Menu.Item>
      <Menu.Item key="history" icon={<BookOutlined />}>
        <Link to="/history">Histórico</Link>
      </Menu.Item>
      <Menu.Item key="book" icon={<BookOutlined />}>
        <Link to="/book">Livro de Ofertas</Link>
      </Menu.Item>
    </Menu>
  )
};
