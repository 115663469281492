/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import tokens from './tokens/sagas';
import fiats from './fiats/sagas';
import balance from './balance/sagas';
import alert from './alert/sagas';
import orders from './orders/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    tokens,
    fiats,
    balance,
    alert,
    orders
  ]);
}
