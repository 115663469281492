/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fiatRequest } from '../../store/modules/fiats/actions';
import { Result, Button } from 'antd';

export default function SuccessSTO () {
  const location = useLocation();
  const dispatch = useDispatch();
  const { fiats = [] } = useSelector(state => state.fiats);
  const { state } = location;

  useEffect(() => {
    dispatch(fiatRequest());
  }, [dispatch])


  const calculateExecuted = useCallback(
    () => {
      return (state.original_quantity - state.quantity) / 100
    }, [state]
  )

  const calculatePending = useCallback(
    () => {
      return state.quantity / 100
    }, [state]
  )

  let message = `${calculateExecuted()} tokens foram comprados com o seu saldo. `;

  if (calculatePending() > 0) {
    message += `${calculatePending()} tokens estão pendentes de liquidação.`
  }

  return (
    <Result
      status='success'
      title={`Compra realizada com sucesso`}
      subTitle={message}
      extra={[
        <Link to='/'>
          <Button type='primary' key='console'>
            Voltar para o início
          </Button>
        </Link>,
      ]}
    />
  );
};
