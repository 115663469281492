/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React from 'react';
import 'antd/dist/antd.css';
import './config';
import { store, persistor } from './store';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Routes from './routes';

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ConfigProvider locale='pt_BR'>
            <Routes />
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
