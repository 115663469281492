/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { takeLatest, put, all, delay } from 'redux-saga/effects';
import Types from './types';

/*
 * This saga is used to capture the SHOW_ALERT action and hide the
 * alert after 3s
 */
export function* showAlert () {
  yield delay(3000);
  yield put({ type: Types.HIDE_ALERT });
}

export default all([
  takeLatest(Types.SHOW_ALERT, showAlert),
]);
