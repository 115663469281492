/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import OrderHistory from '../../components/OrderHistory';
import { Link } from 'react-router-dom';
import { Typography, Alert, Popover, Avatar, Table, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { requestBalance, requestPending } from '../../store/modules/balance/actions';
import { tokensRequest } from '../../store/modules/tokens/actions';
import { fiatRequest } from '../../store/modules/fiats/actions';
import { requestPendingSTOOrders } from '../../store/modules/orders/actions';

const { Title } = Typography;


export default function Balance () {
  const dispatch = useDispatch();
  const { balance = [] } = useSelector(state => state.balance);
  const { pending = [] } = useSelector(state => state.balance);
  const { loading } = useSelector(state => state.balance);
  const { tokens = [] } = useSelector(state => state.tokens);
  const { fiats = [] } = useSelector(state => state.fiats);
  const { pendingStoOrders = [] } = useSelector(state => state.orders);

  const dataSource = (() => {
    if (tokens.length === 0 && balance.length === 0) return [];

    const mergedBalance = balance.reduce((acc, item) => {
      const token = (
        item.token ?
        tokens.find((token) => item.token === token.token_uid)
        : fiats.find((fiat) => item.fiat === fiat.id)
      )

      if (!token) return acc;

      return [...acc, {
        ...token,
        quantity: item.quantity,
        average_price: item.average_price
      }]
    }, [])

    // #XXX: This should be refactored urgently
    const mergedBalanceWithPending = mergedBalance.concat(
      pendingStoOrders
      .reduce((acc, order) => {
        if (acc.find(o => o.token.token_uid === order.token.token_uid)) return acc;

        return [...acc, order];
      }, [])
      .filter((order) => {
        const inBalance = mergedBalance.find((balance) => {
          return balance.token_uid === order.token.token_uid
        });

        if (!inBalance) {
          return true;
        }

        return false;
      })
      .map((order) => {
        return {
          ...order.token,
          id: undefined,
          quantity: 0
        }
      })
    );

    return mergedBalanceWithPending;
  })();

  const calculatePendingSTOs = (asset) => {
    const pending = pendingStoOrders.reduce((acc, order) => {
      if (order.token.token_uid === asset.token_uid) {
        return acc + order.quantity;
      }

      return acc;
    }, 0)

    return pending / 100;
  };

  const calculatePendingDeposits = (asset) => {
    if (asset.id) {
      // FIAT
      return pending.reduce((acc, item) => {
        if (item.fiat.id === asset.id) {
          if (item.operation_type === 'Depósito') {
            return {
              ...acc,
              deposit: acc.deposit + item.quantity
            }
          } else {
            return {
              ...acc,
              withdrawal: acc.withdrawal + item.quantity
            }
          }
        }

        return acc;
      }, {
        deposit: 0,
        withdrawal: 0
      })
    } else {
      // Ignoring token for now.
      return {
        deposit: 0,
        withdrawal: 0
      }
    }
  };

  const isFiat = (item) => {
    return item.id
  };

  const columns = [{
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <div>
          <Avatar src={record.logo} />
          <p style={{
            display: 'inline-block',
            marginLeft: 8
          }}>{ text }</p>
        </div>
      )
    }
  }, {
    title: 'Quantidade',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (text, record) => {
      return (
        <div>
          <p style={{
            display: 'inline-block',
            marginLeft: 8
          }}>{ (text / 100).toFixed(2) } { record.symbol }</p>
        </div>
      )
    }
  }, {
    title: 'Total investido',
    dataIndex: 'total_invested',
    key: 'total_invested',
    render: (text, record) => {
      if (isFiat(record)) return <p>-</p>

      if (!record.average_price) return <p>-</p>

      return <p> { ((record.average_price / 100) * (record.quantity / 100)).toFixed(2) } BRL</p>;
    }
  }, {
    title: 'Valor atualizado',
    dataIndex: 'position',
    key: 'position',
    render: (text, record) => {
      /* The rules are:
       * - If the token is currently being traded on secondary market,
       *   we should get the last Match price and use it.
       *
       * - If the token is not on secondary market yet, we should use the
       *   current token price to calculate user's position
       */
      const {
        quantity,
        orderbook_enabled,
        last_orderbook_price,
        orderbook_start_date,
        price,
        buy_match,
        average_price
      } = record;

      if (orderbook_enabled && orderbook_start_date && (
          new Date(orderbook_start_date).getTime() < new Date().getTime()
      )) {
        if (!last_orderbook_price) return <p>-</p>

        return (
          <p>{ ((quantity / 100) * (last_orderbook_price / 100)).toFixed(2) } BRL</p>
        )
      }

      if (!average_price) return <p>-</p>

      return (
        <p>{ (average_price / 100).toFixed(2) } BRL</p>
      )
    }
  }, {
    title: 'Pendente',
    key: 'pending',
    render: (text, record) => {
      if (!record.id) {
        const pendingSTOs = calculatePendingSTOs(record);

        return (
          <div style={{
            marginLeft: 8,
            display: 'inline-block'
          }}>
            { pendingSTOs > 0 && (
              <Popover content={(
                <p>{ pendingSTOs } { record.symbol }</p>
              )} title='Compras pendentes de liquidação' trigger="hover">
                <p style={{
                  display: 'inline-block',
                  color: '#90EE90'
                }}>+{ pendingSTOs.toFixed(2) }</p>
              </Popover>
            )}
          </div>
        )
      }

      const pending = calculatePendingDeposits(record)

      return (
        <div style={{
          marginLeft: 8,
          display: 'inline-block'
        }}>
          { pending.deposit > 0 && (
            <Popover content={(
              <p>R$ {(pending.deposit / 100).toFixed(2)}</p>
            )} title='Depósitos pendentes de aprovação' trigger="hover">
              <p style={{
                display: 'inline-block',
                color: '#90EE90'
              }}>+{ (pending.deposit / 100).toFixed(2) }</p>
            </Popover>
          )}

          { (pending.deposit > 0 && pending.withdrawal > 0) && (
            <span> / </span>
          )}

          { pending.withdrawal > 0 && (
            <Popover content={(
              <p>R$ {(pending.withdrawal / 100).toFixed(2)}</p>
            )} title='Saques pendentes de aprovação' trigger="hover">
              <p style={{
                display: 'inline-block',
                marginLeft: 0,
                color: '#F08080'
              }}>-{ (pending.withdrawal / 100).toFixed(2) }</p>
            </Popover>
          )}
        </div>
      )
    }
  }, {
    title: 'Ações',
    key: 'action',
    render: (text, record) => {
      if (!isFiat(record)) return <></>

      return (
        <Space size='middle'>
          <Link
            to={{
              pathname: '/deposit' + (isFiat(record) ? '_fiat' : '_token'),
              state: { data: record }
            }}>
            Depositar
          </Link>
          <Link
            to={{
              pathname: '/withdrawal' + (isFiat(record) ? '_fiat' : '_token'),
              state: { data: record }
            }}>
            Saque
          </Link>
        </Space>
      )
    },
  }]

  useEffect(
    () => {
      dispatch(requestBalance());
      dispatch(requestPending());
      dispatch(tokensRequest());
      dispatch(requestPendingSTOOrders());
      dispatch(fiatRequest());
    }, [dispatch]
  )

  return (
    <div>
      <Header title='Carteira' />
      <Navigation current={'balance'} />
      <div style={{
        margin: 16
      }}>
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          pagination={{
            total: dataSource.length,
            pageSize: dataSource.length,
            hideOnSinglePage: true
          }}
        />
        <Title
          style={{
            marginTop: 16
          }}
          level={5}>
          Últimas transações
        </Title>

        <OrderHistory
          paginate={false}
          limit={5} />

        <div style={{
          marginTop: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Link to='/history'>Clique aqui para ver o histórico completo.</Link>
        </div>
      </div>
    </div>
  );
};
