/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useCallback } from 'react';
import { PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/modules/auth/actions';
import UserAvatar from '../UserAvatar';

export default function Header (props) {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onLogout = useCallback(
    () => {
      dispatch(logout())
    }, [dispatch]
  )

  const extra = [
    <UserAvatar key="0" name={user.name} onLogout={onLogout}/>
  ]

  return (
    <PageHeader
      {...props}
      extra={extra}
      style={{
        borderBottom: '1px solid #cecece'
      }}
    />
  )
}
