/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { combineReducers } from 'redux';

import auth from './auth/reducer';
import balance from './balance/reducer';
import tokens from './tokens/reducer';
import fiats from './fiats/reducer';
import orders from './orders/reducer';
import alert from './alert/reducer';

export default combineReducers({
  auth,
  tokens,
  balance,
  fiats,
  alert,
  orders
});
