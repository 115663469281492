/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { fork, all, call, put, select, takeLatest, debounce } from 'redux-saga/effects';
import Types from './types';
import api from '../../../services/api';
import { showAlert } from '../alert/actions';
import jwt_decode from "jwt-decode";
import { handleRequestProblem as handleRequestProblemAction } from './actions';

/**
 * Returns an `access` and a `refresh` token
 * Dispatches SIGNIN_SUCCESS if success and SIGNIN_FAILURE if failure.
 *
 * @param payload User credentials
 * @param payload.email User's email
 * @param payload.password User's password
 */
export function* signin ({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(api.auth, email, password);

    if (!response.ok) throw new Error('Request failed');

    const { access, refresh } = response.data;
    yield put({
      type: Types.SIGNIN_SUCCESS,
      payload: {
        accessToken: access,
        refreshToken: refresh
      }
    })

    yield put({ type: Types.SIGNIN_USER_DETAILS_REQUEST })

  } catch(e) {
    console.log('Auth failed', e)
    yield put({ type: Types.SIGNIN_FAILURE })
  }
}

/**
 * Registers the user in the platform
 * @param payload User credentials
 * @param payload.profile User's profile (normal, professional, qualified)
 * @param payload.name User's name
 * @param payload.email User's email
 * @param payload.password User's password
 * @param payload.cpf User's cpf
 * @param payload.birthdate User's birthdate
 */
export function* signup ({ payload }) {
  const {
    name,
    email,
    password,
    cpf,
    birthdate,
    profile,
    phone
  } = payload;

  const date = birthdate.toISOString().split('T')[0];

  try {
    const response = yield call(api.signup, name, email, password, cpf, date, profile, phone);

    if (!response.ok) {
      if (response.data) {
        const keys = Object.keys(response.data);
        const key = keys[0];

        throw new Error(response.data[key]);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    // const { access, refresh } = response.data;
    yield put({ type: Types.SIGNUP_SUCCESS })
    yield put({
      type: Types.SIGNIN_REQUEST,
      payload: {
        email,
        password
      }
    })
  } catch(e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Register failed', e)
    yield put({ type: Types.SIGNUP_FAILURE })
  }
}

/**
 * Requests user details
 * Dispatches SIGNIN_USER_DETAILS_SUCCESS if success or
 * SIGNIN_USER_DETAILS_SUCCESSif failure.
 */
export function* userDetailsRequest () {
  const { accessToken } = yield select(state => state.auth);

  try {
    const response = yield call(api.getUserDetails, accessToken);

    if (!response.ok) throw new Error('Request failed');

    const {
      approved,
      user,
      reason_for_rejection
    } = response.data;

    yield put({


      type: Types.SIGNIN_USER_DETAILS_SUCCESS,
      payload: {
        user: {
          ...user,
          approved,
          reasonForRejection: reason_for_rejection
        }
      }
    })

  } catch(e) {
    console.log('GET USER DETAILS FAILED:', e)
    yield put({ type: Types.SIGNIN_USER_DETAILS_FAILURE })
  }
}

/**
 * Refreshes the accessToken using the refreshToken
 */
export function* refreshTokenRequest ({ payload }) {
  const { refreshToken } = payload;

  try {
    const response = yield call(api.refreshToken, refreshToken);

    if (!response.ok) throw new Error('Request failed');

    const {
      access,
      refresh
    } = response.data;

    yield put({
      type: Types.REFRESH_TOKEN_SUCCESS,
      payload: {
        accessToken: access
      }
    });

    // XXX: We should store a queue of requests and dispatch them
    // after the token is refreshed
    window.location.reload();
  } catch(e) {
    console.log('REFRESH TOKEN FAILURE', e);
    yield put({ type: Types.REFRESH_TOKEN_FAILURE });
  }
}

export function* handleRequestProblem ({ payload }) {
  const { response } = payload;
  const { status } = response;
  const { refreshToken, accessToken } = yield select(state => state.auth);

  // We are only handling token expired
  if (status !== 401) return;

  if (refreshToken) {
    const { exp } = jwt_decode(refreshToken);

    const dateExpires = new Date(exp * 1000);
    const now = new Date();

    // Check if the refresh token is already expired:
    if (dateExpires > now) {
      console.log('Expired!!')
      yield put({
        type: Types.REFRESH_TOKEN_REQUEST,
        payload: {
          refreshToken
        }
      });

      return;
    }
  }

  yield put({ type: Types.LOGOUT });
}

export function* getUserDocumentsRequest () {
  try {
    const { accessToken } = yield select(state => state.auth);
    const response = yield call(api.requestDocuments, accessToken);

    if (response.problem) {
      yield put(handleRequestProblemAction(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.DOCUMENTS_SUCCESS,
      payload: response.data
    })

  } catch(e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    yield put({ type: Types.DOCUMENTS_FAILURE });
  }
}

export function* uploadDocumentRequest ({ payload }) {
  const { document, id } = payload;
  const { accessToken } = yield select(state => state.auth);

  try {
    const response = yield call(api.uploadDocument, accessToken, id, document);

    if (response.problem) {
      yield put(handleRequestProblemAction(response));
    }

    if (!response.ok) throw new Error('Request failed');

    yield put({
      type: Types.UPLOAD_DOCUMENT_SUCCESS,
      payload: response.data
    })

  } catch(e) {
    console.log('UPLOAD DOCUMENT FAIL', e);
    yield put({ type: Types.UPLOAD_DOCUMENT_FAILURE });
  }
}

export default all([
  takeLatest(Types.SIGNUP_REQUEST, signup),
  takeLatest(Types.SIGNIN_REQUEST, signin),
  takeLatest(Types.SIGNIN_USER_DETAILS_REQUEST, userDetailsRequest),
  takeLatest(Types.REFRESH_TOKEN_REQUEST, refreshTokenRequest),
  takeLatest(Types.UPLOAD_DOCUMENT_REQUEST, uploadDocumentRequest),
  takeLatest(Types.DOCUMENTS_REQUEST, getUserDocumentsRequest),
  debounce(800, Types.HANDLE_REQUEST_PROBLEM, handleRequestProblem),
]);
