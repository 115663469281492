/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { call, all, select, put, takeLatest } from 'redux-saga/effects';
import Types from './types';
import api from '../../../services/api';
import { showAlert } from '../alert/actions';
import { handleRequestProblem } from '../auth/actions';


/**
 * Requests the current logged in User's Balance on the Platform
 *
 * Dispatches BALANCE_SUCCESS with the balance to be stored in case of success
 * or BALANCE_FAILURE in case of request failure.
 */
export function* requestBalance () {
  const { accessToken } = yield select(state => state.auth);

  try {
    const response = yield call(api.getUserBalance, accessToken);

    console.log('response problem: ', response.problem)

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.BALANCE_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Erroed querying balance', e);
    yield put({ type: Types.BALANCE_FAILURE });
  }
}

/**
 * Creates a Withdrawal request on the Platform.
 *
 * Dispatches WITHDRAWAL_SUCCESS in case of success or WITHDRAWAL_FAILURE in
 * case of failure.
 *
 * @param payload Withdrawal details
 * @param payload.fiatId Unique Id of the Fiat to withdrawal
 * @param payload.quantity Quantity of fiats to withdrawal
 * @param payload.history History object to redirect the user to success screen
 */
export function* requestWithdrawal ({ payload }) {
  const { accessToken } = yield select(state => state.auth);
  const { fiatId, quantity, history } = payload;

  try {
    const response = yield call(api.requestWithdrawal, accessToken, fiatId, quantity);

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    history.push('/withdrawal_fiat_success', {
      ...response.data
    })

    yield put({ type: Types.WITHDRAWAL_SUCCESS })
  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Erroed withdrawal fiat', e);
    yield put({ type: Types.WITHDRAWAL_FAILURE });
  }
}

/**
 * Creates a Deposit request on the Platform.
 *
 * Dispatches DEPOSIT_SUCCESS in case of success or DEPOSIT_FAILURE in
 * case of failure.
 *
 * @param payload Deposit details
 * @param payload.fiatId Unique Id of the Fiat to deposit
 * @param payload.quantity Quantity of fiats to deposit
 * @param payload.history History object to redirect the user to success screen
 */
export function* requestDeposit ({ payload }) {
  const { accessToken } = yield select(state => state.auth);
  const { fiatId, quantity, history } = payload;

  try {
    const response = yield call(api.requestDeposit, accessToken, fiatId, quantity);

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    history.push('/deposit_fiat_success', {
      ...response.data
    })

    yield put({ type: Types.DEPOSIT_SUCCESS })
  } catch (e) {
    console.log('Erroed deposit fiat', e);
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    yield put({ type: Types.DEPOSIT_FAILURE });
  }
}

/**
 * Requests all the pending balance operations (Withdrawal or Deposit)
 *
 * Dispatches PENDING_SUCCESS in case of success with the data to be stored
 * Dispatches PENDING_FAILURE in case of failure
 */
export function* requestPending () {
  const { accessToken } = yield select(state => state.auth);

  try {
    const response = yield call(api.requestPending, accessToken);

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.PENDING_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Erroed request pending', e);
    yield put({ type: Types.PENDING_FAILURE });
  }
}

/**
 * Requests the User's balance history
 *
 * Dispatches BALANCE_HISTORY_SUCCESS in case of success with the data to be stored
 * Dispatches BALANCE_HISTORY_FAILURE in case of failure
 */
export function* requestBalanceHistory () {
  const { accessToken } = yield select(state => state.auth);

  try {
    const response = yield call(api.getUserBalanceHistory, accessToken);

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.BALANCE_HISTORY_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Erroed request balance history', e);
    yield put({ type: Types.BALANCE_HISTORY_FAILURE });
  }
}

export default all([
  takeLatest(Types.BALANCE_REQUEST, requestBalance),
  takeLatest(Types.BALANCE_HISTORY_REQUEST, requestBalanceHistory),
  takeLatest(Types.WITHDRAWAL_REQUEST, requestWithdrawal),
  takeLatest(Types.DEPOSIT_REQUEST, requestDeposit),
  takeLatest(Types.PENDING_REQUEST, requestPending),
]);
