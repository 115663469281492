/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route as BaseRoute } from 'react-router-dom';
import UnverifiedPage from '../pages/Unverified';
import { get } from 'lodash';

const Route = ({
  isPrivate = false,
  component: Component,
  layoutProps,
  ...rest
}) => {
  const { user } = useSelector(state => state.auth);
  const authorized = user !== null && user !== undefined;
  const approved = get(user, 'approved') === 1;

  if (!authorized && isPrivate) {
    return <Redirect to="/auth" />;
  }

  if (authorized && !isPrivate) {
    return <Redirect to="/" />;
  }

  if (authorized && !approved) {
    return (
      <BaseRoute
        {...rest}
        render={props => (
          <UnverifiedPage {...props} />
        )}
      />
    );
  }

  return (
    <BaseRoute
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  );
};

Route.defaultProps = {
  isPrivate: false,
  layoutProps: {},
};

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  layoutProps: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default Route;

