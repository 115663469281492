/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { handleRequestProblem } from '../auth/actions';

import Types from './types';

/**
 * Requests all the Fiats registered on the platform
 *
 * Dispatches FIAT_SUCCESS with the fiat data to be stored in case of success
 * Dispatches FIAT_FAILURE with the fiat data to be stored in case of failure
 */
export function* fiatRequest () {
  try {
    const { accessToken } = yield select(state => state.auth);

    const response = yield call(api.getFiats, accessToken);
    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) throw new Error('Request failed');

    yield put({
      type: Types.FIAT_SUCCESS,
      payload: response.data
    })

  } catch (e) {
    console.log('Request erroed: ', e);
    yield put({ type: Types.FIAT_FAILURE })
  }
}

export default all([
  takeLatest(Types.FIAT_REQUEST, fiatRequest),
]);
