/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import { Avatar, Table, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { STORequest } from '../../store/modules/tokens/actions';

export default function STO () {
  const { stos = [] } = useSelector(state => state.tokens);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(STORequest());
    }, [dispatch]
  )

  const dataSource = stos.map((token, index) => ({
    key: index,
    ...token
  }))

  const columns = [{
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <div>
          <Link
            to={{
              pathname: '/token_info',
              state: { token: record }
            }}>
            <Avatar src={record.logo} />
            <p style={{
              display: 'inline-block',
              marginLeft: 8
            }}>{ text }</p>
          </Link>
        </div>
      )
    }
  }, {
    title: 'Símbolo',
    dataIndex: 'symbol',
    key: 'symbol',
  }, {
    title: 'Quantidade Disponível',
    dataIndex: 'owned_by_platform',
    key: 'owned_by_platform',
    render: (text, record) => {
      return (
        <p>{ text / 100 }</p>
      )
    }
  }, {
    title: 'Ações',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <Link
          to={{
            pathname: '/token_info',
            state: { token: record }
          }}>
          Info
        </Link>
      </Space>
    ),
  }]

  return (
    <div>
      <Header title='Lista de Ativos' />
      <Navigation current={'sto'} />
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};
