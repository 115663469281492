/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useState } from 'react';
import Header from '../../../components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Divider } from 'antd';


export default function Deposit () {
  const [copyState, setCopyState] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const { token } = location.state;

  return (
    <div>
      <Header
        onBack={() => history.goBack()}
        title={`Deposit: ${token.name}`} />

      <div style={{
        paddingTop: 36,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <QRCode
          size={200}
          value='NOT_YET_DEVELOPED' />

        <CopyToClipboard
          style={{
            marginTop: 36,
          }}
          text={'NOT_YET_DEVELOPED'}
          onCopy={() => setCopyState(true)}>
          <button>{ copyState ? 'Copied!' : 'Copy address to clipboard' }</button>
        </CopyToClipboard>

        <Divider />

        <h1>History</h1>
      </div>
    </div>
  );
};
