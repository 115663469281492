/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fiatRequest } from '../../../store/modules/fiats/actions';
import { Result, Button } from 'antd';

export default function SuccessFiat () {
  const location = useLocation();
  const dispatch = useDispatch();
  const { fiats = [] } = useSelector(state => state.fiats);
  const { state } = location;

  const { id } = state.fiat
  const fiat = fiats.find(f => f.id === id) || {}

  useEffect(() => {
    dispatch(fiatRequest());
  }, [dispatch])

  return (
    <Result
      status='success'
      title={`Seu pedido de saque de  ${(state.quantity/100).toFixed(2)} ${fiat.symbol} está pendente de aprovação da plataforma e em breve será avaliado`}
      extra={[
        <Link to='/'>
          <Button type='primary' key='console'>
            Voltar para o início
          </Button>
        </Link>,
      ]}
    />
  );
};
