/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Statistic } from 'antd';
import { get } from 'lodash';
import { Form, Input, InputNumber, Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useWindowDimensions } from '../../../utils';
import QRCode from 'qrcode.react';
import Header from '../../../components/Header';

export default function WithdrawlToken () {
  const [copyState, setCopyState] = useState(false);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  const { balance = [] } = useSelector(state => state.balance);
  const { token } = location.state;

  const balanceToken = balance.find((bt) => {
    return token.tokenName === bt.tokenName;
  })

  const onMax = useCallback(
    () => {
      form.setFieldsValue({
        withdrawlValue: token.total
      });
    }, [form, token]
  );

  return (
    <div>
      <Header
        onBack={() => history.goBack()}
        title={`Withdrawl: ${token.tokenName}`} />

      <div style={{
        display: 'flex',
        padding: 36,
        flexDirection: width > 560 ? 'row' : 'column'
      }}>
        <div style={{
          flex: 2
        }}>
          <Statistic title={`${token.name} (${token.tokenName})`} value={get(balanceToken, 'total')} />
          <Form form={form}>
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
            }}>
              <Form.Item label="Value to Withdrawl" name="withdrawlValue">
                <InputNumber />
              </Form.Item>
              <Button onClick={onMax}>Max</Button>
            </div>

            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>

            <Button
              htmlType="submit"
              type="primary"
              block>
              Confirm
            </Button>
          </Form>
        </div>
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 16
        }}>
          <QRCode
            size={86}
            value='NOT_YET_DEVELOPED' />

          <CopyToClipboard
            style={{
              marginTop: 36,
            }}
            text={'NOT_YET_DEVELOPED'}
            onCopy={() => setCopyState(true)}>
            <button>{ copyState ? 'Copied!' : 'Copy configuration string to clipboard' }</button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};
