/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import Types from './types';

/**
 * Requests the current logged in User's balance
 */
export function requestBalance () {
  return { type: Types.BALANCE_REQUEST }
};

/**
 * Requests the current logged in User's balance history
 */
export function requestBalanceHistory () {
  return { type: Types.BALANCE_HISTORY_REQUEST }
};

/**
 * Creates a Withdrawal request on the Platform
 *
 * @param payload Withdrawal details
 * @param payload.fiatId Fiat unique identifier to withdrawal
 * @param payload.quantity Fiat quantity to withdrawal
 * @param payload.history react-router-dom History object to redirect user to
 * success screen
 */
export function requestWithdrawal ({ fiatId, quantity, history }) {
  return {
    type: Types.WITHDRAWAL_REQUEST,
    payload: {
      fiatId,
      quantity,
      history
    }
  }
};

/**
 * Creates a Deposit request on the Platform
 *
 * @param payload Deposit details
 * @param payload.fiatId Fiat unique identifier to deposit
 * @param payload.quantity Fiat quantity to deposit
 * @param payload.history react-router-dom History object to redirect user to
 * success screen
 */
export function requestDeposit ({ fiatId, quantity, history }) {
  return {
    type: Types.DEPOSIT_REQUEST,
    payload: {
      fiatId,
      quantity,
      history
    }
  }
};

/**
 * Requests pending balance operations (withdrawal/deposit)for the current
 * logged in user.
 */
export function requestPending () {
  return { type: Types.PENDING_REQUEST }
};
