/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import VanillaForm from 'react-vanilla-form';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled(VanillaForm)`
  width: 100%;
  max-width: 450px;
  padding: 20px;
  border-radius: 6px;

  input + input {
    margin-top: 10px;
  }

  input + button {
    margin-top: 20px;
  }
`;

