/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { showAlert } from '../alert/actions';
import { handleRequestProblem } from '../auth/actions';

import Types from './types';

/**
 * Requests all the tokens available on the platform
 *
 * Dispatches TOKENS_SUCCESS in case of success with the tokens data to be stored or
 * TOKENS_FAILURE in case of failure
 */
export function* tokensRequest () {
  try {
    const { accessToken } = yield select(state => state.auth);

    const response = yield call(api.getTokens, accessToken);

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.TOKENS_SUCCESS,
      payload: response.data
    })

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.TOKENS_FAILURE })
  }
}

/**
 * Requests all the STO available on the platform
 *
 * Dispatches STO_SUCCESS in case of success with the sto data to be stored or
 * STO_FAILURE in case of failure
 */
export function* STORequest () {
  try {
    const { accessToken } = yield select(state => state.auth);

    const response = yield call(api.getSTOs, accessToken);

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.STO_SUCCESS,
      payload: response.data
    })

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.STO_FAILURE })
  }
}

/**
 * Creates a STO Buy request
 *
 * Dispatches BUY_STO_SUCCESS in case of success or
 * BUY_STO_FAILURE in case of failure
 *
 * Displays a notification on the screen, with all the matches that happened
 * if any.
 *
 * @param payload Payload for the request
 * @param payload.tokenUid Token unique identifier on the Platform
 * @param payload.fiatId Fiat unique identifier on the Platform
 * @param payload.quantity Quantity to create
 * @param payload.history History object to redirect the user to the Success
 * screen
 */
export function* buyStoRequest ({ payload }) {
  try {
    const { accessToken } = yield select(state => state.auth);
    const { tokenUid, fiatId, quantity, history } = payload;

    const response = yield call(api.buySTO, accessToken, tokenUid, fiatId, quantity);

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.BUY_STO_SUCCESS,
      payload: response.data
    })

    history.push('/sto_buy_success', {
      ...response.data
    })

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.BUY_STO_FAILURE })
  }
}

export default all([
  takeLatest(Types.TOKENS_REQUEST, tokensRequest),
  takeLatest(Types.STO_REQUEST, STORequest),
  takeLatest(Types.BUY_STO_REQUEST, buyStoRequest),
]);
