/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  accessToken: null,
  refreshToken: null,
  user: null,
  loading: false,
  error: false,
  documents: []
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.DOCUMENTS_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });
    case Types.DOCUMENTS_SUCCESS:
      return produce(state, draft => {
        const documents = action.payload;

        draft.loading = false;
        draft.error = false;
        draft.documents = documents
      });
    case Types.DOCUMENTS_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.documents = [];
      });
    case Types.REFRESH_TOKEN_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });
    case Types.REFRESH_TOKEN_SUCCESS:
      return produce(state, draft => {
        const { accessToken } = action.payload;

        draft.accessToken = accessToken;
        draft.loading = false;
      });
    case Types.REFRESH_TOKEN_FAILURE:
      return INITIAL_STATE;

    case Types.SIGNIN_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case Types.SIGNIN_SUCCESS:
      return produce(state, draft => {
        const { accessToken, refreshToken } = action.payload;

        draft.accessToken = accessToken;
        draft.refreshToken = refreshToken;
        draft.error = false;
        draft.loading = false;
      });
    case Types.SIGNIN_FAILURE:
      return produce(state, draft => {
        draft.user = null;
        draft.accessToken = null;
        draft.refreshToken = null;
        draft.error = true;
        draft.loading = false;
      });

    case Types.SIGNIN_APPROVE:
      return produce(state, draft => {
        draft.user = {
          ...state.user,
          verified: true
        }
      });

    // USER DETAILS

    case Types.SIGNIN_USER_DETAILS_REQUEST:
      return produce(state, draft => {
        draft.error = false;
        draft.loading = true;
      });

    case Types.SIGNIN_USER_DETAILS_FAILURE:
      return produce(state, draft => {
        draft.error = true;
        draft.loading = false;
        draft.user = null;
      });

    case Types.SIGNIN_USER_DETAILS_SUCCESS:
      const { user } = action.payload;

      return produce(state, draft => {
        draft.error = false;
        draft.loading = false;
        draft.user = user;
      });

    case Types.UPLOAD_DOCUMENT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });
    case Types.UPLOAD_DOCUMENT_SUCCESS:
      return produce(state, draft => {
        const documents = action.payload;

        draft.loading = false;
        draft.error = false;
        draft.documents = documents
      });
    case Types.UPLOAD_DOCUMENT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case Types.SIGNUP_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });
    case Types.SIGNUP_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
      });
    case Types.SIGNUP_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case Types.HANDLE_REQUEST_PROBLEM:
      return state;

    case Types.LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
