/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
export default {
  TOKENS_REQUEST: '@TOKENS/TOKENS_REQUEST',
  TOKENS_SUCCESS: '@TOKENS/TOKENS_SUCCESS',
  TOKENS_FAILURE: '@TOKENS/TOKENS_FAILURE',

  STO_REQUEST: '@TOKENS/STO_REQUEST',
  STO_SUCCESS: '@TOKENS/STO_SUCCESS',
  STO_FAILURE: '@TOKENS/STO_FAILURE',

  BUY_STO_REQUEST: '@TOKENS/BUY_STO_REQUEST',
  BUY_STO_SUCCESS: '@TOKENS/BUY_STO_SUCCESS',
  BUY_STO_FAILURE: '@TOKENS/BUY_STO_FAILURE',
};
