/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useState, useEffect, useCallback } from 'react';
import Header from '../Header';
import Navigation from '../Navigation';
import { Avatar, Table, Space, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestOrders } from '../../store/modules/orders/actions';
import { requestBalanceHistory } from '../../store/modules/balance/actions';
import { EXPLORER_URL } from '../../config';

const TYPES = {
  'STO_BUY': 'Compra de STO',
  'ORDERBOOK_BUY': 'Compra no Livro de Ofertas',
  'ORDERBOOK_SELL': 'Venda no Livro de Ofertas',
  'Depósito': 'Depósito de Fiat',
  'Saque': 'Saque de Fiat'
};

const STATUS_COLOR = {
  'Aprovado': 'green',
  'Rejeitado': 'red',
  'Pendente': '',
};

export default function OrderHistory ({
  limit,
  enableFilters,
  paginate,
  paginationPosition = 'topRight'
}) {
  const { orders = [] } = useSelector(state => state.orders);
  const { balanceHistory = [] } = useSelector(state => state.balance);
  const [expandableRowKeys, setExpandableRowKeys] = useState([]);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(requestOrders());
      dispatch(requestBalanceHistory());
    }, [dispatch]
  );

  const newOrders = orders.map((order) => {
    const {
      token,
      fiat,
      created_at,
      quantity,
      original_quantity,
      buy_match,
      sell_match,
      type,
      status,
      id
    } = order;

    return {
      id,
      name: token.name,
      symbol: token.symbol,
      type: TYPES[type],
      quantity: original_quantity,
      executed: quantity,
      datetime: new Date(created_at),
      matches: buy_match.length > 0 ? buy_match : sell_match,
      fiat,
      status,
      expandable: buy_match.length > 0 || sell_match.length > 0
    }
  });

  const newBalanceHistory = balanceHistory.map((balanceOperation) => {
    const {
      operation_type,
      created_at,
      status,
      fiat,
      quantity
    } = balanceOperation;

    return {
      name: fiat.name,
      symbol: fiat.symbol,
      type: TYPES[operation_type],
      status,
      quantity,
      datetime: new Date(created_at),
      expandable: false
    }
  });

  const merged = [...newBalanceHistory, ...newOrders];
  const dataSource = merged
    .sort((a, b) => b.datetime.getTime() - a.datetime.getTime())
    .splice(0, limit ? limit : merged.length);


  const onTableRowExpand = useCallback(
    (expanded, record) => {
      const keys = [];
      if (expanded){
        keys.push(record.id);
      }

      setExpandableRowKeys(keys);
    },
    [expandableRowKeys]
  )

  const renderExpandableRow = useCallback(
    (record) => {
      const {
        matches,
        symbol
      } = record;

      return (
        <ul>
          {
            matches.map((match, index) => {
              return (
                <li>
                  { (match.quantity / 100).toFixed(2) }
                  &nbsp;
                  {symbol}
                  &nbsp;
                  @{ (match.price / 100).toFixed(2) } BRL
                  &nbsp;
                  em
                  &nbsp;
                  {new Date(match.executed_at).toLocaleString()}
                  &nbsp;
                  { match.blockchaintransaction.length > 0 ? (
                    <a href={`${EXPLORER_URL}/transaction/${match.blockchaintransaction}`}>
                      Ver na blockchain
                    </a>
                  ) : (
                    <p style={{
                      display: 'inline'
                    }}>— Transação Pendente</p>
                  )}
                </li>

              )
            })
          }
        </ul>
      )
    }, []
  )

  const columns = [{
    title: 'Ativo',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <div>
          <p style={{
            display: 'inline-block',
            marginLeft: 8
          }}>{ text }</p>
        </div>
      )
    }
  }, {
    title: 'Data e Hora',
    dataIndex: 'datetime',
    key: 'datetime',
    defaultSortOrder: enableFilters ? 'descend' : undefined,
    sorter: enableFilters ? (a, b) => {
      const datetimeA = a.datetime;
      const datetimeB = b.datetime;

      return datetimeA.getTime() - datetimeB.getTime();
    } : undefined,
    render: (text, record) => {
      return (
        <div>
          <p style={{
            display: 'inline-block',
            marginLeft: 8
          }}>{ new Date(text).toLocaleString() }</p>
        </div>
      )
    }
  }, {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    filters: enableFilters ? [{
      text: 'Compra de STO',
      value: 'Compra de STO',
    }, {
      text: 'Compra no Livro de Ofertas',
      value: 'Compra no Livro de Ofertas'
    }, {
      text: 'Venda no Livro de Ofertas',
      value: 'Venda no Livro de Ofertas'
    }, {
      text: 'Depósito de Fiat',
      value: 'Depósito de Fiat'
    }, {
      text: 'Saque de Fiat',
      value: 'Saque de Fiat'
    }] : undefined,
    onFilter: (value, record) => record.type.indexOf(value) === 0
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: enableFilters ? [{
      text: 'Aprovado',
      value: 'Aprovado',
    }, {
      text: 'Rejeitado',
      value: 'Rejeitado'
    }, {
      text: 'Pendente',
      value: 'Pendente'
    }] : undefined,
    onFilter: (value, record) => record.status && record.status.indexOf(value) === 0,
    render: (text, record) => {
      return <Tag color={STATUS_COLOR[record.status]}>{ text }</Tag>
    }
  }, {
    title: 'Quantidade',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (text, record) => {
      if (record.type === 'Venda no Livro de Ofertas' ||
          record.type === 'Compra no Livro de Ofertas') {

        const originalQuantity = record.quantity
        const executed = (originalQuantity - record.executed)

        if (executed < originalQuantity) {
          return (
            <p>{(executed / 100).toFixed(2)}/{(originalQuantity / 100).toFixed(2)} {record.symbol}</p>
          )
        }
      }

      return (
        <p>{ (text / 100).toFixed(2) }</p>
      )
    }
  }, {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
    render: (text, record) => {
      const { txid } = record;

      return (
        <p>{ txid }</p>
      )
    }
  }]

  return (
    <Table
      bordered
      dataSource={dataSource}
      columns={columns}
      rowKey={(record) => record.id}
      expandedRowKeys={expandableRowKeys}
      onExpand={onTableRowExpand}
      expandable={{
        expandedRowRender: renderExpandableRow,
        rowExpandable: record => record.expandable
      }}
      pagination={{
        total: dataSource.length,
        pageSize: paginate ? limit : dataSource.length,
        hideOnSinglePage: true,
        position: [paginationPosition]
      }}
    />
  );
};
