/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';

export default function UserAvatar ({ name, onLogout }) {
  const menu = (
    <Menu>
      <Menu.Item key='0' onClick={onLogout}>
        Logout
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Avatar key='0'>{ name }</Avatar>
    </Dropdown>
  )
}
