/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React from 'react';
import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import { showAlert } from '../alert/actions';
import { handleRequestProblem } from '../auth/actions';

import Types from './types';

export function* ordersRequest () {
  try {
    const { accessToken } = yield select(state => state.auth);

    const response = yield call(api.getOrders, accessToken);

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.ORDERS_SUCCESS,
      payload: response.data
    })

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.ORDERS_FAILURE })
  }
}

/**
 * Creates the User's order history for a given pair
 *
 * Dispatches ORDERBOOK_MY_ORDERS_SUCCESS with the data to be stored in case of
 * success or ORDERBOOK_MY_ORDERS_FAILURE in case of failure
 *
 * @param payload Pair data
 * @param payload.pair Order Pair in SYMBOL_SYMBOL' format
 */
export function* requestMyOrders ({ payload }) {
  try {
    const { accessToken } = yield select(state => state.auth);
    const { pair } = payload;

    const response = yield call(api.requestOrderbookUserOrders, accessToken, pair);

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.ORDERBOOK_MY_ORDERS_SUCCESS,
      payload: response.data
    })

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.ORDERBOOK_MY_ORDERS_FAILURE })
  }
}

/**
 * Requests the current orderbook for a given pair
 *
 * Dispatches ORDERBOOK_BOOK_SUCCESS with the data to be stored in case of
 * success or ORDERBOOK_BOOK_FAILURE in case of failure
 *
 * @param payload Pair data
 * @param payload.pair Order Pair in SYMBOL_SYMBOL' format
 */
export function* orderBookRequest ({ payload }) {
  const { pair } = payload;

  try {
    const { accessToken } = yield select(state => state.auth);

    const response = yield call(api.getOrderBook, accessToken, pair);
    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.ORDERBOOK_BOOK_SUCCESS,
      payload: response.data
    });

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.ORDERBOOK_BOOK_FAILURE })
  }
}

/**
 * Requests all Orders from the platform that are Pending.
 *
 * Dispatches ORDERS_PENDING_STO_SUCCESS with the data to be stored in case of
 * success or ORDERS_PENDING_STO_FAILURE in case of failure
 */
export function* requestPendingSTOOrders () {
  try {
    const { accessToken } = yield select(state => state.auth);

    const response = yield call(api.requestPendingSTOOrders, accessToken);

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.ORDERS_PENDING_STO_SUCCESS,
      payload: response.data
    })

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.ORDERS_PENDING_STO_FAILURE })
  }
}

/**
 * Cancels an order from the orderbook
 *
 * Dispatches ORDERBOOK_CANCEL_ORDER_SUCCESS in case of success or
 * ORDERBOOK_CANCEL_ORDER_FAILURE in case of failure
 *
 * @param payload Payload for the request
 * @param payload.pair Order Pair in SYMBOL_SYMBOL' format
 * @param payload.orderId Order unique identifier
 * @param payload.notification Notification object to notify the User of the
 * response
 */
export function* cancelOrder ({ payload }) {
  try {
    const { accessToken } = yield select(state => state.auth);
    const {
      pair,
      orderId,
      notification
    } = payload;

    const response = yield call(api.cancelOrder, accessToken, pair, orderId);

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({ type: Types.ORDERBOOK_CANCEL_ORDER_SUCCESS })

    yield put({
      type: Types.ORDERBOOK_BOOK_REQUEST,
      payload: { pair }
    })

    yield put({
      type: Types.ORDERBOOK_MY_ORDERS_REQUEST,
      payload: { pair }
    })


    notification.open({
      message: 'Ordem cancelada com sucesso'
    });

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.ORDERBOOK_CANCEL_ORDER_FAILURE })
  }
}

/**
 * Creates an order on the orderbook
 *
 * Dispatches ORDERBOOK_ORDER_SUCCESS in case of success or
 * ORDERBOOK_ORDER_FAILURE in case of failure
 *
 * Displays a notification on the screen, with all the matches that happened
 * if any.
 *
 * @param payload Payload for the request
 * @param payload.pair Order Pair in SYMBOL_SYMBOL' format
 * @param payload.quantity Quantity to create
 * @param payload.price Price of the Order
 * @param payload.type Can be either ORDERBOOK_BUY or ORDERBOOK_SELL
 * @param payload.notification Notification object to notify the User of the
 * response
 */
export function* createOrderBookOrder ({ payload }) {
  try {
    const { accessToken } = yield select(state => state.auth);
    const {
      notification,
      quantity,
      price,
      type,
      pair
    } = payload;

    const response = yield call(api.createOrderBookOrder,
      accessToken, quantity, price, type, pair);

    if (response.problem) {
      yield put(handleRequestProblem(response));
    }

    if (!response.ok) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      throw new Error('Ocorreu um problema. Tente de novo mais tarde.');
    }

    yield put({
      type: Types.ORDERBOOK_ORDER_SUCCESS,
      payload: response.data
    })

    yield put({
      type: Types.ORDERBOOK_BOOK_REQUEST,
      payload: { pair }
    })

    yield put({
      type: Types.ORDERBOOK_MY_ORDERS_REQUEST,
      payload: { pair }
    })

    // Notify
    if (response.data.type === 'ORDERBOOK_BUY') {
      const { buy_match } = response.data;

      notification.open({
        message: `Ordem de compra de ${quantity / 100} ${pair} adicionada com sucesso`,
        description: (
          <ul>
            {
              buy_match.reduce((acc, match) => {
                return [...acc, (
                  <li>Compra de {match.quantity / 100} {pair.split('_')[0]} executada.</li>
                )]
              }, '')
            }
          </ul>
        )
      });

      console.log(buy_match);
    } else if (response.data.type === 'ORDERBOOK_SELL') {
      const { sell_match } = response.data;

      notification.open({
        message: `Ordem de venda de ${quantity / 100} ${pair} adicionada com sucesso`,
        description: (
          <ul>
            {
              sell_match.reduce((acc, match) => {
                return [...acc, (
                  <li>Venda de {match.quantity / 100} {pair.split('_')[0]} executada.</li>
                )]
              }, '')
            }
          </ul>
        )
      });
    }

  } catch (e) {
    yield put(showAlert({
      message: 'Erro',
      description: e.message,
      type: 'error'
    }));
    console.log('Request erroed: ', e);
    yield put({ type: Types.ORDERBOOK_ORDER_FAILURE })
  }
}

export default all([
  takeLatest(Types.ORDERS_REQUEST, ordersRequest),
  takeLatest(Types.ORDERBOOK_ORDER_REQUEST, createOrderBookOrder),
  takeLatest(Types.ORDERBOOK_BOOK_REQUEST, orderBookRequest),
  takeLatest(Types.ORDERBOOK_MY_ORDERS_REQUEST, requestMyOrders),
  takeLatest(Types.ORDERS_PENDING_STO_REQUEST, requestPendingSTOOrders),
  takeLatest(Types.ORDERBOOK_CANCEL_ORDER_REQUEST, cancelOrder),
]);
