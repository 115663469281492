/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useEffect, useCallback, useRef } from 'react';
import Header from '../../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { userDetailsRequest, requestUserDocuments, uploadDocumentRequest } from '../../store/modules/auth/actions';
import { Button } from 'antd';
import {
  CheckCircleOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';

const iconStyle = {
  fontSize: 24
}

const DOCUMENT_ICON = {
  'pending': <QuestionCircleOutlined style={iconStyle} />,
  'approved': <CheckCircleOutlined style={{...iconStyle, color: 'yellowgreen'}} />,
  'rejected': <CloseCircleOutlined style={{...iconStyle, color: 'crimson' }} />,
  'sent': <FieldTimeOutlined style={iconStyle} />
};

export default function Unverified () {
  const dispatch = useDispatch();
  const { documents = [] } = useSelector(state => state.auth);
  const uploadRef = useRef(null);

  useEffect(
    () => {
      dispatch(requestUserDocuments());
      dispatch(userDetailsRequest());
    }, [dispatch]
  )

  const onChange = useCallback(
    (document, e) => {
      const file = e.target.files[0];

      const { id } = document;
      const { name, type } = file;

      const payload = {
        id,
        document: {
          file,
          name,
          type,
        }
      }

      dispatch(uploadDocumentRequest(payload))
    }, [dispatch]
  )

  return (
    <div style={{
      height: '100%'
    }}>
      <Header title='Pendente de Aprovação' />
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 60
      }}>
        <h1 style={{
          marginBottom: 16
        }}>
          Seu perfil ainda está pendente de aprovação.
        </h1>

        <p style={{
          marginBottom: 60,
          maxWidth: 450,
          textAlign: 'center'
        }}>
          Alguns documentos podem estar pendentes de envio e serão solicitados por nossa equipe
        </p>

        {
          documents.map((document) => {
            return (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                maxWidth: 500,
                marginTop: 8
              }}>
                <div style={{
                }}>
                  { DOCUMENT_ICON[document.status] }
                </div>
                <div style={{
                  flex: 1,
                  marginLeft: 16,
                  flexDirection: 'column'
                }}>
                  <p style={{
                    marginBottom: 0,
                    fontWeight: 'bold'
                  }}>
                    { document.name }
                  </p>
                  <p style={{
                    marginTop: 2
                  }}>
                    { document.reason_for_rejection }
                  </p>
                </div>
                <div style={{
                  marginLeft: 16
                }}>
                  {
                    document.loading && (
                      <LoadingOutlined />
                    )
                  }

                  <label style={{
                    borderRadius: 5,
                    cursor: 'pointer'
                  }} for={`file-selector-${document.id}`}>
                    <UploadOutlined style={{ fontSize: 18}} />
                  </label>

                  <input
                    key={document.id}
                    onChange={(e) => onChange(document, e)}
                    id={`file-selector-${document.id}`} style={{
                      display: 'none'
                    }} type="file" />
                </div>

              </div>
            )
          })
        }
      </div>
    </div>
  );
};
