/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { BalancePage } from '../pages';
import AuthPage from '../pages/Auth';
import RegisterPage from '../pages/Register';
import STOPage from '../pages/STO';
import SuccessSTO from '../pages/STO/Success';
import BookPage from '../pages/Book';
import HistoryPage from '../pages/History';
import TokenInfo from '../pages/TokenInfo';
import { Alert } from 'antd';
import {
  DepositFiat,
  DepositFiatSuccess,
  DepositToken
} from '../pages/Deposit';

import {
  WithdrawlFiat,
  WithdrawlFiatSuccess,
  WithdrawlToken
} from '../pages/Withdrawl';

import Route from './Route';

export default function ApplicationRoutes() {
  const {
    showAlert,
    message,
    description,
    type
  } = useSelector(state => state.alert);

  return (
    <>
      { showAlert && (
        <Alert
          message={message}
          description={description}
          type='error'
          showIcon
          closable={true} />
      )}
      <Switch>
        <Route exact isPrivate path="/" component={BalancePage} />
        <Route isPrivate path="/sto" component={STOPage} />
        <Route isPrivate path="/history" component={HistoryPage} />
        <Route isPrivate path="/sto_buy_success" component={SuccessSTO} />
        <Route isPrivate path="/book" component={BookPage} />
        <Route isPrivate path="/token_info" component={TokenInfo} />
        <Route isPrivate path="/deposit_fiat" component={DepositFiat} />
        <Route isPrivate path="/deposit_token" component={DepositToken} />
        <Route isPrivate path="/withdrawal_fiat" component={WithdrawlFiat} />
        <Route
          isPrivate
          path="/withdrawal_fiat_success"
          component={WithdrawlFiatSuccess} />
        <Route
          isPrivate
          path="/deposit_fiat_success"
          component={DepositFiatSuccess} />
        <Route isPrivate path="/withdrawal_token" component={WithdrawlToken} />
        <Route path="/auth" component={AuthPage} />
        <Route path="/register" component={RegisterPage} />
      </Switch>
    </>
  );
}
