/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, InputNumber, Button } from 'antd';
import { requestDeposit } from '../../../store/modules/balance/actions';
import Header from '../../../components/Header';

export default function DepositFiat () {

  const [depositValue, setDepositValue] = useState(0);
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data = {} } = location.state;

  const { loading } = useSelector(state => state.balance);

  const onDeposit = useCallback(
    () => {
      const newValue = Math.trunc(depositValue * 100)

      dispatch(requestDeposit({
        fiatId: data.id,
        quantity: newValue,
        history
      }));
    },
    [dispatch, depositValue, data, history]
  );

  return (
    <div>
      <Header
        onBack={() => history.goBack()}
        title={`Depósito de Fiat`} />

      <div style={{
        display: 'flex',
        padding: 36,
        flexDirection: 'row'
      }}>
        <div style={{
          flex: 2,
          maxWidth: 600
        }}>
          <Form form={form}>
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
            }}>

              <Form.Item label="Valor para depositar R$:" name="depositValue">
                <InputNumber
                  style={{ width: 300 }}
                  name="depositValue"
                  decimalSeparator=','
                  precision={2}
                  onChange={setDepositValue}/>
              </Form.Item>
            </div>

            <Button
              onClick={onDeposit}
              htmlType="submit"
              type="primary"
              loading={loading}
              block>
              Confirmar
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
