/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useState, useCallback, useEffect } from 'react';
import Header from '../../components/Header';
import { useLocation, useHistory } from 'react-router-dom';
import { InputNumber, Button, Modal, Layout, Descriptions, List } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { requestBalance } from '../../store/modules/balance/actions';
import { buySTORequest } from '../../store/modules/tokens/actions';

const { Content } = Layout;

export default function TokenInfo () {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [buyAmount, setBuyAmount] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { token } = location.state;
  const { balance = [] } = useSelector(state => state.balance);

  const fiat = balance.find(item => item.fiat !== null) || {}

  const calculateMax = useCallback(
    () => {
      const max = ((token.quantity / 100) - getNegociatedValue(token))

      return max
    }, [token]
  )

  const handleOk = useCallback(
    () => {
      if (!acceptTerms) {
        setAcceptTerms(true);
      } else {
        dispatch(buySTORequest({
          tokenUid: token.token_uid,
          quantity: Math.trunc(buyAmount * 100),
          fiatId: fiat.fiat,
          history
        }));
      }
    }, [
      fiat,
      token,
      buyAmount,
      history,
      acceptTerms,
      dispatch
    ]
  )

  const handleCancel = useCallback(
    () => {
      setVisible(false);
    }, []
  )

  const onBuyClick = useCallback(
    () => {
      setVisible(true);
    }, []
  )

  const onBuyAmountChange = useCallback(
    (amount) => {
      setBuyAmount(amount);
    }, []
  )

  const onMaxClick = useCallback(
    () => {
      setBuyAmount(calculateMax())
    }, []
  )

  const getNegociatedValue = useCallback(
    (token) => {
      return (token.quantity - token.owned_by_platform) / 100
    }, [token]
  )

  useEffect(
    () => {
      dispatch(requestBalance());
    }, [dispatch]
  )

  return (
    <>
      <div>
        <Header
          onBack={() => history.goBack()}
          title={`STO: ${token.name} (${token.symbol})`} />

        { !token.stable && (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16
          }}>
            <Button onClick={onBuyClick}>Comprar esse token</Button>
          </div>
        )}

        <Content style={{ padding: 16 }}>
          <Descriptions bordered>
            <Descriptions.Item label='Nome'>{ token.name }</Descriptions.Item>
            <Descriptions.Item label='Preço por token'> R$ { token.price / 100 }</Descriptions.Item>
            <Descriptions.Item label='Valor Total'> R$ { (token.quantity / 100) * (token.price / 100) }</Descriptions.Item>
            <Descriptions.Item label='Símbolo'>{ token.symbol }</Descriptions.Item>
            <Descriptions.Item label='Iniciado em'>{ new Date(token.created_at).toLocaleDateString() }</Descriptions.Item>
            <Descriptions.Item label='Quantidade Total'>{ token.quantity / 100 }</Descriptions.Item>
            <Descriptions.Item label='Quantidade já negociada'>{ getNegociatedValue(token) }</Descriptions.Item>
            <Descriptions.Item label='Detalhes'>
              { token.description }
            </Descriptions.Item>
          </Descriptions>
          <br/>
          <List
            header={<div>Documentos</div>}
            dataSource={token.documents.filter((doc) => doc.active)}
            renderItem={item => (
              <List.Item>
                <a href={item.document}>{item.name}</a>
              </List.Item>
            )}
          />
        </Content>
      </div>

      <Modal
        title='Comprar'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}>
        { !acceptTerms && (
          <>
            <p>Preço: R$ { token.price / 100 }</p>
            <p>Seu saldo atual: R$ { fiat.quantity / 100 }</p>
            <p onClick={onMaxClick}>Quantidade máxima: { calculateMax() } { token.symbol }</p>
            <label>
              Quantidade
              <InputNumber
                style={{
                  marginLeft: 8
                }}
                onChange={onBuyAmountChange}
                min={0}
                max={calculateMax()}
                type='number'
                value={(buyAmount && buyAmount.toFixed) ? buyAmount.toFixed(2) : 0} />
            </label>
            <p style={{
              marginTop: 8
            }}><b>Total:</b> R$ { (buyAmount * (token.price / 100)).toFixed(2) } </p>
          </>
        )}
        { acceptTerms && (
          <>
            <h1>Termos de serviço e condições</h1>
            <p>
At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.
            </p>
          </>
        )}
      </Modal>
    </>
  );
};
