/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
if (process.env.NODE_ENV === 'development') {
  require('./reactotron');
}

export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL || "https://explorer.testnet.hathor.network";
