/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
export default {
  BALANCE_REQUEST: '@BALANCE/BALANCE_REQUEST',
  BALANCE_SUCCESS: '@BALANCE/BALANCE_SUCCESS',
  BALANCE_FAILURE: '@BALANCE/BALANCE_FAILURE',

  WITHDRAWAL_REQUEST: '@BALANCE/WITHDRAWAL_REQUEST',
  WITHDRAWAL_SUCCESS: '@BALANCE/WITHDRAWAL_SUCCESS',
  WITHDRAWAL_FAILURE: '@BALANCE/WITHDRAWAL_FAILURE',

  DEPOSIT_REQUEST: '@BALANCE/DEPOSIT_REQUEST',
  DEPOSIT_SUCCESS: '@BALANCE/DEPOSIT_SUCCESS',
  DEPOSIT_FAILURE: '@BALANCE/DEPOSIT_FAILURE',

  PENDING_REQUEST: '@BALANCE/PENDING_REQUEST',
  PENDING_SUCCESS: '@BALANCE/PENDING_SUCCESS',
  PENDING_FAILURE: '@BALANCE/PENDING_FAILURE',

  BALANCE_HISTORY_REQUEST: '@BALANCE/BALANCE_HISTORY_REQUEST',
  BALANCE_HISTORY_SUCCESS: '@BALANCE/BALANCE_HISTORY_SUCCESS',
  BALANCE_HISTORY_FAILURE: '@BALANCE/BALANCE_HISTORY_FAILURE',
};
