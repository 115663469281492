/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Statistic } from 'antd';
import { Form, InputNumber, Button } from 'antd';
import { requestWithdrawal } from '../../../store/modules/balance/actions';
import Header from '../../../components/Header';

export default function WithdrawlFiat () {

  const [withdrawalValue, setWithdrawalValue] = useState(0);
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data = {} } = location.state;

  const { loading } = useSelector(state => state.balance);

  const onMax = useCallback(
    () => {
      const { quantity } = data

      form.setFieldsValue({
        withdrawlValue: (quantity / 100).toFixed(2)
      });

      setWithdrawalValue((quantity / 100).toFixed(2))
    }, [form, data]
  );

  const onWithdrawal = useCallback(
    () => {
      const newValue = Math.trunc(withdrawalValue * 100)

      dispatch(requestWithdrawal({
        fiatId: data.id,
        quantity: newValue ? newValue.toFixed(2) : newValue,
        history
      }));
    },
    [dispatch, withdrawalValue, data, history]
  );

  return (
    <div>
      <Header
        onBack={() => history.goBack()}
        title={`Saque de Fiat`} />

      <div style={{
        display: 'flex',
        padding: 36,
        flexDirection: 'row'
      }}>
        <div style={{
          flex: 2
        }}>
          <Statistic title={`${data.name} (${data.symbol})`} value={data.quantity/100} />
          <Form form={form}>
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
            }}>

              <Form.Item label="Valor para sacar em R$:" name="withdrawlValue">
                <InputNumber
                  name="withdrawlValue"
                  decimalSeparator=','
                  precision={2}
                  onChange={setWithdrawalValue}/>
              </Form.Item>

              <Button onClick={onMax}>Máximo</Button>
            </div>

            <Button
              onClick={onWithdrawal}
              htmlType="submit"
              type="primary"
              loading={loading}
              block>
              Confirmar
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
