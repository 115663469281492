/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import { OrderBook } from '@lab49/react-order-book';
import { Tabs } from 'antd';
import {
  notification,
  Space,
  Table,
  Tag,
  Form,
  InputNumber,
  Button
} from 'antd';
import { useWindowDimensions } from '../../utils';
import { tokensRequest } from '../../store/modules/tokens/actions';
import {
  requestMyOrders,
  requestOrderBook,
  createOrderBookOrder,
  cancelOrder
} from '../../store/modules/orders/actions';
import { Select } from 'antd';

import './styles.css';

const { Option } = Select;

const TYPE_COLOR = {
  'ORDERBOOK_BUY': 'green',
  'ORDERBOOK_SELL': 'red'
};

const TYPES = {
  'ORDERBOOK_BUY': 'Compra',
  'ORDERBOOK_SELL': 'Venda'
};

export default function Book () {
  const [selectedPair, setSelectedPair] = useState(null);
  const [buyQuantity, setBuyQuantity] = useState(0);
  const [sellQuantity, setSellQuantity] = useState(0);
  const [buyPrice, setBuyPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);

  const { width } = useWindowDimensions();
  const { tokens = [] } = useSelector(state => state.tokens);
  const { orderbook = [] } = useSelector(state => state.orders);
  const { myOrders = [] } = useSelector(state => state.orders);
  const dispatch = useDispatch();

  const orderbookTokens = tokens.filter((token) => {
    return token.orderbook_enabled &&
      (new Date(token.orderbook_start_date).getTime() < new Date().getTime())
  });
  const availablePairs = orderbookTokens.map((token) => {
    return `${token.symbol}_BRL`;
  });

  useEffect(
    () => {
      dispatch(tokensRequest());
    }, [dispatch]
  )

  const onCancel = useCallback(
    (order) => {
      dispatch(cancelOrder({
        orderId: order.id,
        pair: selectedPair,
        notification
      }))
    }, [selectedPair]
  )

  const onPairSelect = useCallback(
    (pair) => {
      setSelectedPair(pair);
      dispatch(requestOrderBook({ pair }));
      dispatch(requestMyOrders({ pair }));
    }, []
  )

  const addBuyOrder = useCallback(
    () => {
      dispatch(createOrderBookOrder({
        type: 'ORDERBOOK_BUY',
        pair: selectedPair,
        quantity: Math.trunc(buyQuantity * 100),
        price: Math.trunc(buyPrice * 100),
        notification,
      }))
    }, [
      dispatch,
      selectedPair,
      buyQuantity,
      buyPrice,
    ]
  )

  const addSellOrder = useCallback(
    () => {
      dispatch(createOrderBookOrder({
        type: 'ORDERBOOK_SELL',
        pair: selectedPair,
        quantity: Math.trunc(sellQuantity * 100),
        price: Math.trunc(sellPrice * 100),
        notification,
      }))
    }, [
      dispatch,
      selectedPair,
      sellQuantity,
      sellPrice,
    ]
  )

  const book = orderbook.reduce((acc, order) => {
    if (order.type === 'ORDERBOOK_SELL') {
      const asks = [...acc.asks];
      const formattedPrice = order.price / 100;

      const foundRow = asks.find((ask) => ask[0] === formattedPrice);

      if (foundRow) {
        const newRow = [...foundRow];
        newRow[1] = newRow[1] + (order.quantity / 100);

        const index = asks.indexOf(foundRow);
        asks[index] = newRow;
      } else {
        asks.push([formattedPrice, order.quantity / 100]);
      }

      return {
        ...acc,
        asks: asks.sort((a, b) => {
          if (a[0] > b[0]) return 1;
          if (a[0] < b[0]) return -1;

          return 0;
        })
      }
    } else {
      const bids = [...acc.bids];
      const formattedPrice = order.price / 100;

      const foundRow = bids.find((bid) => bid[0] === formattedPrice);

      if (foundRow) {
        const newRow = [...foundRow];
        newRow[1] = newRow[1] + (order.quantity / 100);

        const index = bids.indexOf(foundRow);
        bids[index] = newRow;
      } else {
        bids.push([formattedPrice, order.quantity / 100]);
      }

      return {
        ...acc,
        bids: bids.sort((a, b) => {
          if (a[0] > b[0]) return -1;
          if (a[0] < b[0]) return 1;

          return 0;
        })
      }
    }

    return acc;
  }, {
    asks: [],
    bids: [],
  });

  const spread = (() => {
    if (book.asks.length === 0 || book.bids.length === 0) return 0;

    const lastAsk = book.asks[0][0];
    const lastBid = book.bids[0][0];

    return (lastAsk - lastBid).toFixed(2);
  })();

  const columns = [{
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    render: (text, record) => {
      return (
        <Tag
          color={TYPE_COLOR[text]}>
          {TYPES[text]}
        </Tag>
      )
    }
  }, {
    title: 'Data/Hora',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) => {
      return (
        <p> { new Date(text).toLocaleString() } </p>
      )
    }
  }, {
    title: 'Quantidade Total',
    dataIndex: 'original_quantity',
    key: 'original_quantity',
    render: (text, record) => {
      return (
        <p>{ (text / 100).toFixed(2) }</p>
      )
    }
  }, {
    title: 'Quantidade Pendente',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (text, record) => {
      return (
        <p>{ (text / 100).toFixed(2) }</p>
      )
    }
  }, {
    title: 'Valor Total',
    dataIndex: 'total_value',
    key: 'total_value',
    render: (text, record) => {
      const totalValue = ((record.price * record.original_quantity) / 10000).toFixed(2)
      return (
        <p>{ totalValue }</p>
      )
    }
  }, {
    title: 'Valor Pendente',
    dataIndex: 'pending_value',
    key: 'pending_value',
    render: (text, record) => {
      const pendingValue = ((record.price * record.quantity) / 10000).toFixed(2)
      return (
        <p>{ pendingValue }</p>
      )
    }
  }, {
    title: 'Valor Pendente',
    dataIndex: 'pending_value',
    key: 'pending_value',
    render: (text, record) => {
      const pendingValue = ((record.price * record.quantity) / 10000).toFixed(2)
      return (
        <p>{ pendingValue }</p>
      )
    }
  }, {
    title: 'Ações',
    key: 'action',
    render: (text, record) => {
      return (
        <Space size='middle'>
          <Button onClick={() => onCancel(record)}>
            Cancelar
          </Button>
        </Space>
      )
    }
  }]

  return (
    <div>
      <Header title='Livro de Ofertas' />
      <Navigation current={'book'} />

      <div style={{
        display: 'flex',
        padding: 16,
        flexDirection: width > 560 ? 'row' : 'column'
      }}>
        <div style={{
          border: '1px solid rgba(0, 0, 0, 0.05)'
        }}>
        <OrderBook
          stylePrefix='hathor-orderbook'
          book={book}
          fullOpacity
          listLength={10}
          showHeaders
          interpolateColor={(color) => color}
          spread={spread}
          showSpread={true} />
        </div>
        <div style={{
          border: '1px solid rgba(0, 0, 0, 0.05)',
          borderLeft: 0,
          padding: 16,
          flex: 1,
          display: 'flex'
        }}>
          <div style={{
            marginLeft: 16
          }}>
            <Select
              style={{ width: 120 }}
              value={selectedPair}
              onChange={onPairSelect}>
              {availablePairs.map((pair) =>
                <Option value={pair}>{ pair }</Option>
              )}
            </Select>
            {selectedPair && (
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Comprar" key="buy">
                  <Form>
                    <Form.Item label="Quantidade" name="quantity">
                      <InputNumber
                        style={{ width: 120 }}
                        decimalSeparator=','
                        precision={2}
                        onChange={setBuyQuantity}/>
                    </Form.Item>

                    <Form.Item label="Preço da Unidade" name="unitValue">
                      R$
                      <InputNumber
                        style={{ width: 120 }}
                        decimalSeparator=','
                        precision={2}
                        onChange={setBuyPrice}/>
                    </Form.Item>

                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={addBuyOrder}
                      block>
                      Adicionar Ordem
                    </Button>
                  </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Vender" key="sell">
                  <Form>
                    <Form.Item label="Quantidade" name="quantity">
                      <InputNumber
                        style={{ width: 120 }}
                        decimalSeparator=','
                        precision={2}
                        onChange={setSellQuantity}/>
                    </Form.Item>

                    <Form.Item label="Preço da Unidade" name="unitValue">
                      R$
                      <InputNumber
                        style={{ width: 120 }}
                        decimalSeparator=','
                        precision={2}
                        onChange={setSellPrice}/>
                    </Form.Item>

                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={addSellOrder}
                      block>
                      Adicionar Ordem
                    </Button>
                  </Form>
                </Tabs.TabPane>
              </Tabs>
            )}
          </div>
        </div>
      </div>

      <Table
        bordered
        size='small'
        columns={columns}
        dataSource={myOrders} />
    </div>
  );
};
