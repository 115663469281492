/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useCallback/*, useState*/ } from 'react';
import Input from '../../components/Input';
import { Container, Form } from './styles'
import { Alert, Button } from 'antd';
import { signin } from '../../store/modules/auth/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';


export default function Auth () {
  const { loading, error } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const authenticate = useCallback(
    ({ email, password }) => {
      dispatch(signin({ email, password }));
    },
    [dispatch]
  );

  return (
    <Container style={{
      paddingTop: 80,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Form onSubmit={authenticate}>
        <label>
          Email:
          <Input type='text' name='email' />
        </label>

        <label>
          Password:
          <Input type='password' name='password' />
        </label>

        { error && (
          <Alert
            style={{ marginBottom: 16 }}
            message='Invalid email or password'
            type="error" />
        )}

        <Button
          htmlType='submit'
          type='primary'
          loading={loading}
          block>
          Entrar
        </Button>
      </Form>
      <Link
        to={{
          pathname: '/register',
        }}>
        Não possui cadastro? Clique aqui para se cadastrar.
      </Link>
    </Container>
  );
};
