/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  fiats: [],
  loading: false
};

export default function fiats (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FIAT_REQUEST:
      return produce(state, draft => {
        draft.loading = false
      });
    case Types.FIAT_SUCCESS:
      const { payload } = action;

      return produce(state, draft => {
        draft.loading = false
        draft.fiats = payload
      });
    case Types.FIAT_FAILURE:

      return produce(state, draft => {
        draft.loading = false
      });

    default:
      return state;
  }
};
