/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import Types from './types';

/**
 * Signs the user in the platform.
 *
 * @param payload User credentials
 * @param payload.email User's email
 * @param payload.password User's password
 */
export function signin ({ email, password }) {
  return {
    type: Types.SIGNIN_REQUEST,
    payload: { email, password },
  };
};

/**
 * Registers the user in the platform
 *
 * @param payload User credentials
 * @param payload.profile User's profile (normal, professional, qualified)
 * @param payload.name User's name
 * @param payload.email User's email
 * @param payload.password User's password
 * @param payload.cpf User's cpf
 * @param payload.birthdate User's birthdate
 */
export function signup ({
  profile,
  name,
  email,
  password,
  cpf,
  birthdate,
  phone
}) {
  return {
    type: Types.SIGNUP_REQUEST,
    payload: {
      profile,
      name,
      email,
      password,
      cpf,
      birthdate,
      phone
    },
  };
};

/**
 * Query user documents
 */
export function requestUserDocuments () {
  return { type: Types.DOCUMENTS_REQUEST };
};

/**
 * Uploads a file for a pending document
 *
 * @param payload.id The document id
 * @param payload.document Document to upload
 * @param payload.document.file The file object
 * @param payload.document.type The document type
 * @param payload.document.name The document name
 */
export function uploadDocumentRequest ({ document, id }) {
  return {
    type: Types.UPLOAD_DOCUMENT_REQUEST,
    payload: {
      document,
      id
    }
  };
};

/**
 * Received on every request that received the
 * `problem` property on response.
 *
 * @param payload Response data
 * @param payload.response response object from axios
 */
export function handleRequestProblem (response) {
  return {
    type: Types.HANDLE_REQUEST_PROBLEM,
    payload: {
      response: response
    }
  };
};

/**
 * Refreshes the accessToken using the refreshToken
 */
export function refreshToken (refreshToken) {
  return {
    type: Types.REFRESH_TOKEN_REQUEST,
    payload: {
      refreshToken
    }
  };
};

/**
 * Requests the current User's details.
 */
export function userDetailsRequest () {
  return { type: Types.SIGNIN_USER_DETAILS_REQUEST };
};

/**
 * Logs the user out, removing his access token from storage.
 */
export function logout () {
  return {
    type: Types.LOGOUT,
  };
};
