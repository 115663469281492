/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useCallback/*, useState*/ } from 'react';
import { Container } from './styles'
import { DatePicker, Form, Input, Alert, Button, Select } from 'antd';
import { signup } from '../../store/modules/auth/actions';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const validateMessages = {
  required: 'Esse campo é obrigatório!'
};

export default function Register () {
  const { loading, error } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const authenticate = useCallback(
    (data) => {
      dispatch(signup(data));
    },
    [dispatch]
  );

  const onProfileChange = useCallback(
    () => {
      console.log('Profile changed')
    },
    [dispatch]
  );



  return (
    <Container style={{
      paddingTop: 80
    }}>
      <Form onFinish={authenticate} {...formItemLayout} validateMessages={validateMessages}>

        <h1>
          Qual é o seu perfil de investidor?
        </h1>

        <Form.Item name="profile">

          <Select
            placeholder="Selecione uma opção"
            onChange={onProfileChange}
            rules={[{ required: true }]}
            allowClear>

            <Option value="normal">Comum</Option>
            <Option value="qualified">Qualificado</Option>
            <Option value="professional">Profissional</Option>
          </Select>

        </Form.Item>

        <h1>
          Dados do usuário
        </h1>

        <Form.Item name="name" label="Nome completo" rules={[{ required: true }]}>
          <Input type='text' name='name' />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{
            type: 'email',
            message: 'O e-mail inserido não é válido',
          }, {
            required: true,
            message: 'Este campo é obrigatório',
          }]}>
          <Input type='text' name='email' />
        </Form.Item>

        <Form.Item
          name="confirm_email"
          label="Confirme seu email"
          dependencies={['email']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme seu email!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Os emails não batem!');
              },
            }),
          ]}>
          <Input type='text' name='confirm_email' />
        </Form.Item>

        <Form.Item name="password" label="Senha" rules={[{ required: true }]}>
          <Input type='password' name='password' />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          label="Confirmar senha"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme sua senha!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('As senhas não batem');
              },
            }),
          ]}
          >
          <Input type='password' name='confirm_password' />
        </Form.Item>

        <Form.Item name="cpf" label="CPF" rules={[{ required: true }]}>
          <Input type='text' name='cpf' />
        </Form.Item>

        <Form.Item name="birthdate" label="Data de nascimento" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>

        <Form.Item name="phone" label="Telefone" rules={[{ required: true }]}>
          <Input type='number' />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Criar usuário
        </Button>
      </Form>
    </Container>
  );
};
