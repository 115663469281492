/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  orders: [],
  orderbook: [],
  pendingStoOrders: []
};

export default function orders(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case Types.ORDERS_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.ORDERS_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.orders = payload;
      });

    case Types.ORDERS_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // ORDERBOOK_BOOK

    case Types.ORDERBOOK_BOOK_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.ORDERBOOK_BOOK_SUCCESS:
      return produce(state, draft => {
        draft.orderbook = payload;
        draft.loading = false;
      });

    case Types.ORDERBOOK_BOOK_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // ORDERBOOK_ORDER

    case Types.ORDERBOOK_ORDER_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.ORDERBOOK_ORDER_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
      });

    case Types.ORDERBOOK_ORDER_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // ORDERBOOK_MY_ORDERS

    case Types.ORDERBOOK_MY_ORDERS_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.ORDERBOOK_MY_ORDERS_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.myOrders = payload;
      });

    case Types.ORDERBOOK_MY_ORDERS_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // ORDERS_PENDING_STO

    case Types.ORDERS_PENDING_STO_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.ORDERS_PENDING_STO_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.pendingStoOrders = payload;
      });

    case Types.ORDERS_PENDING_STO_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // ORDERBOOK_CANCEL_ORDER

    case Types.ORDERBOOK_CANCEL_ORDER_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.ORDERBOOK_CANCEL_ORDER_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
      });

    case Types.ORDERBOOK_CANCEL_ORDER_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
};
