/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import { Avatar, Table, Space, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestOrders } from '../../store/modules/orders/actions';
import { requestBalanceHistory } from '../../store/modules/balance/actions';
import OrderHistory from '../../components/OrderHistory';

const TYPES = {
  'STO_BUY': 'Compra de STO',
  'ORDERBOOK_BUY': 'Compra no Livro de Ofertas',
  'ORDERBOOK_SELL': 'Venda no Livro de Ofertas',
  'Depósito': 'Depósito de Fiat',
  'Saque': 'Saque de Fiat'
};

const STATUS_COLOR = {
  'Aprovado': 'green',
  'Rejeitado': 'red',
  'Pendente': ''
};

export default function History () {

  return (
    <div>
      <Header title='Histórico' />
      <Navigation current={'history'} />
      <div style={{
        margin: 16
      }}>
      <OrderHistory paginate={true} />

      </div>
    </div>
  );
};
