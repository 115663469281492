/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
export default {
  SIGNIN_REQUEST: '@AUTH/SIGNIN_REQUEST',
  SIGNIN_SUCCESS: '@AUTH/SIGNIN_SUCCESS',
  SIGNIN_FAILURE: '@AUTH/SIGNIN_FAILURE',
  SIGNIN_APPROVE: '@AUTH/SIGNIN_APPROVE',

  SIGNIN_USER_DETAILS_REQUEST: '@AUTH/SIGNIN_USER_DETAILS_REQUEST',
  SIGNIN_USER_DETAILS_SUCCESS: '@AUTH/SIGNIN_USER_DETAILS_SUCCESS',
  SIGNIN_USER_DETAILS_FAILURE: '@AUTH/SIGNIN_USER_DETAILS_FAILURE',

  HANDLE_REQUEST_PROBLEM: '@AUTH/HANDLE_REQUEST_PROBLEM',

  LOGOUT: '@AUTH/LOGOUT',

  REFRESH_TOKEN_REQUEST: '@AUTH/REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: '@AUTH/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: '@AUTH/REFRESH_TOKEN_FAILURE',

  UPLOAD_DOCUMENT_REQUEST: '@AUTH/UPLOAD_DOCUMENT_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS: '@AUTH/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_FAILURE: '@AUTH/UPLOAD_DOCUMENT_FAILURE',

  DOCUMENTS_REQUEST: '@AUTH/DOCUMENTS_REQUEST',
  DOCUMENTS_SUCCESS: '@AUTH/DOCUMENTS_SUCCESS',
  DOCUMENTS_FAILURE: '@AUTH/DOCUMENTS_FAILURE',

  SIGNUP_REQUEST: '@AUTH/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: '@AUTH/SIGNUP_SUCCESS',
  SIGNUP_FAILURE: '@AUTH/SIGNUP_FAILURE',
};
