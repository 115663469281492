/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
export default {
  FIAT_REQUEST: '@FIATS/FIAT_REQUEST',
  FIAT_SUCCESS: '@FIATS/FIAT_SUCCESS',
  FIAT_FAILURE: '@FIATS/FIAT_FAILURE',
};
