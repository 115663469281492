/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import Types from './types';

/**
 * Requests all the Tokens on the platform
 */
export function tokensRequest() {
  return { type: Types.TOKENS_REQUEST };
};

/**
 * Requests all the STOs on the platform
 */
export function STORequest() {
  return { type: Types.STO_REQUEST };
};

/**
 * Creates a Buy STO request on the Platform
 *
 * @param payload Request details
 * @param payload.tokenUid Unique identifier of the Token to buy
 * @param payload.fiatId Unique identifier of the Fiat
 * @param payload.quantity Quantity to create
 * @param payload.history History object to redirect the user to success screen
 */
export function buySTORequest({ tokenUid, fiatId, quantity, history }) {
  return {
    type: Types.BUY_STO_REQUEST,
    payload: {
      tokenUid,
      fiatId,
      quantity,
      history
    }
  };
};
