/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import Types from './types';

/**
 * Displays an alert on the frontend
 *
 * @param payload Alert details
 * @param payload.message Alert message
 * @param payload.description Alert description
 * @param payload.type Alert type, can be one of ['success', 'warning',
 * 'info', 'error']
 */
export function showAlert ({ showAlert=true, message, description, type='success' }) {
  return {
    type: Types.SHOW_ALERT,
    payload: {
      showAlert,
      message,
      description,
      type
    },
  };
};
