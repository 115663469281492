/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  showAlert: false,
  message: '',
  description: '',
  type: 'success'
};

export default function alert(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_ALERT:
      const { showAlert, message, description, type } = action.payload;
      return produce(state, draft => {
        draft.showAlert = true;
        draft.message = message;
        draft.description = description;
        draft.type = type;
      });

    case Types.HIDE_ALERT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
