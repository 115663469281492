/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import apisauce from 'apisauce'

export const BASE_URL = '/api'
export const TIMEOUT = 10000

const create = (baseURL = BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {},
    timeout: TIMEOUT
  });

  const _getHeaders = (token, centralId) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return headers
  };

  const auth = (email, password) => {
    return api.post('/auth/', {
      username: email,
      password
    })
  };

  const signup = (name, email, password, cpf, birthdate, profile, phone) => {
    return api.post('/register', {
      name,
      email: email,
      password,
      cpf,
      birthdate,
      profile,
      phone
    })
  };

  const getUserDetails = (token) => {
    const headers = _getHeaders(token)

    return api.get('/users/info', null, { headers })
  };

  const getTokens = (token) => {
    const headers = _getHeaders(token);

    return api.get('/tokens', null, { headers })
  };

  const getOrders = (token) => {
    const headers = _getHeaders(token);

    return api.get('/orders', null, { headers })
  };

  const requestPendingSTOOrders = (token) => {
    const headers = _getHeaders(token);

    return api.get('/orders?orderbook=false&status=opened',
      null, { headers })
  };

  const getSTOs = (token) => {
    const headers = _getHeaders(token);

    return api.get('/tokens/active', null, { headers })
  };

  const buySTO = (token, tokenUid, fiatId, quantity) => {
    const headers = _getHeaders(token);

    return api.post('/tokens/buy/', {
      token_uid: tokenUid,
      fiat_id: fiatId,
      quantity
    }, { headers })
  };

  const requestDocuments = (token) => {
    const headers = _getHeaders(token);

    return api.get(`users/documents/`, null, { headers })
  };

  const uploadDocument = (token, id, document) => {
    const headers = _getHeaders(token);
    const { file, type, name } = document;

    const formData = new FormData();
    formData.append('file', file);

    return api.put(`/users/documents/${id}/`, formData, { headers: {
      ...headers,
      'content-type': 'multipart/form-data',
    }})
  };

  const refreshToken = (refresh) => {
    return api.post('/auth/refresh/', {
      refresh: refresh
    }, {})
  };

  const createOrderBookOrder = (token, quantity, price, type, pair) => {
    const headers = _getHeaders(token);

    return api.post(`/orderbook/${pair}/orders/`, {
      quantity,
      price,
      type
    }, { headers })
  };

  const cancelOrder = (token, pair, id) => {
    const headers = _getHeaders(token);

    return api.delete(`/orderbook/${pair}/orders/${id}/`, {}, { headers });
  };

  const getOrderBook = (token, pair) => {
    const headers = _getHeaders(token);

    return api.get(`/orderbook/${pair}/orders/`, null, { headers })
  };

  const requestOrderbookUserOrders = (token, pair) => {
    const headers = _getHeaders(token);

    return api.get(`/orderbook/${pair}/orders/user/`, null, { headers })
  };

  const getFiats = (token) => {
    const headers = _getHeaders(token);

    return api.get('/fiats', null, { headers })
  };

  const getUserBalance = (token) => {
    const headers = _getHeaders(token);

    return api.get('/balance', null, { headers })
  };

  const getUserBalanceHistory = (token) => {
    const headers = _getHeaders(token);

    return api.get('/balance/history', null, { headers })
  };

  const requestWithdrawal = (token, fiatId, quantity) => {
    const headers = _getHeaders(token);

    return api.post('/balance/withdrawal/', {
      fiat_id: fiatId,
      quantity: quantity
    }, { headers })
  };

  const requestDeposit = (token, fiatId, quantity) => {
    const headers = _getHeaders(token);

    return api.post('/balance/deposit/', {
      fiat_id: fiatId,
      quantity: quantity
    }, { headers })
  };

  const requestPending = (token) => {
    const headers = _getHeaders(token);

    return api.get('/balance/pending', null, { headers })
  };

  return {
    auth,
    signup,
    getUserDetails,
    getUserBalance,
    getUserBalanceHistory,
    getTokens,
    getSTOs,
    buySTO,
    requestOrderbookUserOrders,
    createOrderBookOrder,
    requestPendingSTOOrders,
    getOrderBook,
    getFiats,
    getOrders,
    cancelOrder,
    requestWithdrawal,
    requestDeposit,
    requestPending,
    refreshToken,
    uploadDocument,
    requestDocuments
  };
}

const api = create();

export default api;
