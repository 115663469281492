/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
export default {
  ORDERS_REQUEST: '@ORDERS/ORDERS_REQUEST',
  ORDERS_SUCCESS: '@ORDERS/ORDERS_SUCCESS',
  ORDERS_FAILURE: '@ORDERS/ORDERS_FAILURE',

  ORDERBOOK_ORDER_REQUEST: '@ORDERS/ORDERBOOK_ORDER_REQUEST',
  ORDERBOOK_ORDER_SUCCESS: '@ORDERS/ORDERBOOK_ORDER_SUCCESS',
  ORDERBOOK_ORDER_FAILURE: '@ORDERS/ORDERBOOK_ORDER_FAILURE',

  ORDERBOOK_CANCEL_ORDER_REQUEST: '@ORDERS/ORDERBOOK_CANCEL_ORDER_REQUEST',
  ORDERBOOK_CANCEL_ORDER_SUCCESS: '@ORDERS/ORDERBOOK_CANCEL_ORDER_SUCCESS',
  ORDERBOOK_CANCEL_ORDER_FAILURE: '@ORDERS/ORDERBOOK_CANCEL_ORDER_FAILURE',

  ORDERBOOK_BOOK_REQUEST: '@ORDERS/ORDERBOOK_BOOK_REQUEST',
  ORDERBOOK_BOOK_SUCCESS: '@ORDERS/ORDERBOOK_BOOK_SUCCESS',
  ORDERBOOK_BOOK_FAILURE: '@ORDERS/ORDERBOOK_BOOK_FAILURE',

  ORDERBOOK_MY_ORDERS_REQUEST: '@ORDERS/ORDERBOOK_MY_ORDERS_REQUEST',
  ORDERBOOK_MY_ORDERS_SUCCESS: '@ORDERS/ORDERBOOK_MY_ORDERS_SUCCESS',
  ORDERBOOK_MY_ORDERS_FAILURE: '@ORDERS/ORDERBOOK_MY_ORDERS_FAILURE',

  ORDERS_PENDING_STO_REQUEST: '@ORDERS/ORDERS_PENDING_STO_REQUEST',
  ORDERS_PENDING_STO_SUCCESS: '@ORDERS/ORDERS_PENDING_STO_SUCCESS',
  ORDERS_PENDING_STO_FAILURE: '@ORDERS/ORDERS_PENDING_STO_FAILURE',
};
