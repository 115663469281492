/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import { Form, Input as BaseInput } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export default function Input({ error, help, style, className, ...props }) {
  return (
    <Form.Item
      validateStatus={error ? 'error' : ''}
      help={error || help || ''}
      style={style}
      className={className}
    >
      <BaseInput {...props} />
    </Form.Item>
  );
}

Input.propTypes = {
  error: PropTypes.string,
  help: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
};

Input.defaultProps = {
  error: undefined,
  help: undefined,
  style: {},
  className: undefined,
};
