/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import Types from './types';

/**
 * Requests the current User's orders
 */
export function requestOrders () {
  return {
    type: Types.ORDERS_REQUEST
  };
};

/**
 * Requests the current User's pending STO orders
 */
export function requestPendingSTOOrders () {
  return { type: Types.ORDERS_PENDING_STO_REQUEST };
};

/**
 * Requests the current orderbook for a given pair
 *
 * @param payload Request details
 * @param payload.pair Pair in `SYMBOL_SYMBOL'` format
 */
export function requestOrderBook ({ pair }) {
  return {
    type: Types.ORDERBOOK_BOOK_REQUEST,
    payload: {
      pair
    }
  };
};

/**
 * Cancels an orderbook order
 *
 * @param payload Request details
 * @param payload.pair Pair in `SYMBOL_SYMBOL'` format
 * @param payload.orderId Order unique identifier
 * @param payload.notification antd Notification object to display data to
 * the user
 */
export function cancelOrder ({ orderId, pair, notification }) {
  return {
    type: Types.ORDERBOOK_CANCEL_ORDER_REQUEST,
    payload: {
      orderId,
      pair,
      notification
    }
  };
};

/**
 * Fetches current logged in User's orders
 *
 * @param payload Request details
 * @param payload.pair Pair in `SYMBOL_SYMBOL'` format
 */
export function requestMyOrders ({ pair }) {
  return {
    type: Types.ORDERBOOK_MY_ORDERS_REQUEST,
    payload: {
      pair
    }
  };
};

/**
 * Creates an orderbook order
 *
 * @param payload Request details
 * @param payload.pair Pair in `SYMBOL_SYMBOL'` format
 * @param payload.quantity Quantity to create
 * @param payload.price Price of the Order
 * @param payload.type Can be either ORDERBOOK_BUY or ORDERBOOK_SELL
 * @param payload.notification antd Notification object to display data to
 * the user
 */
export function createOrderBookOrder ({ type, price, quantity, pair, notification }) {
  return {
    type: Types.ORDERBOOK_ORDER_REQUEST,
    payload: {
      type,
      price,
      quantity,
      pair,
      notification
    }
  };
};
