/**
 * Copyright 2020 Hathor Labs
 * This software is provided ‘as-is’, without any express or implied
 * warranty. In no event will the authors be held liable for any damages
 * arising from the use of this software.
 * This software cannot be redistributed unless explicitly agreed in writing with the authors.
 **/
import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  balance: [],
  balanceHistory: [],
  pending: [],
  loading: false
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.BALANCE_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.balance = [];
      });

    case Types.BALANCE_SUCCESS:
      return produce(state, draft => {
        const { payload } = action;

        draft.loading = false;
        draft.balance = payload;
      });

    case Types.BALANCE_FAILURE:
      return produce(state, draft => {

        draft.loading = false;
        draft.balance = [];
      });

    // Withdrawal

    case Types.WITHDRAWAL_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.WITHDRAWAL_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
      });

    case Types.WITHDRAWAL_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // Deposit

    case Types.DEPOSIT_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.DEPOSIT_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
      });

    case Types.DEPOSIT_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
      });

    // Pending BalanceOperations

    case Types.PENDING_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
      });

    case Types.PENDING_SUCCESS:
      return produce(state, draft => {
        const { payload } = action;

        draft.loading = false;
        draft.pending = payload;
      });

    case Types.PENDING_FAILURE:
      return produce(state, draft => {

        draft.loading = false;
        draft.pending = [];
      });

    // History

    case Types.BALANCE_HISTORY_REQUEST:
      return produce(state, draft => {
        draft.loading = true;
        draft.balanceHistory = [];
      });

    case Types.BALANCE_HISTORY_SUCCESS:
      return produce(state, draft => {
        const { payload } = action;

        draft.loading = false;
        draft.balanceHistory = payload;
      });

    case Types.BALANCE_HISTORY_FAILURE:
      return produce(state, draft => {

        draft.loading = false;
        draft.balanceHistory = [];
      });
    default:
      return state;
  }
};
